// 系统配置-设备台账管理-第三方设备管理
<template>
    <div class="page_container height_100" v-loading="loading">
        <div class="bgc_FFFFFF height_100">
            <div class="box_head1">
                <span>第三方设备台账管理</span>
                <div class="flex align_center mg_left_50 fn_size14">
                </div>
            </div>
            <!-- mg_top_20 -->
            <div class="flex  pdl30 pdr30 pdt_20">
                <el-button type="primary" class="width_110 mg_left_10" icon="el-icon-circle-plus-outline"
                    style=" background: #03CABE;border: none;" @click="addData">新增
                </el-button>
                <el-button type="primary" class="mg_left_10" style=" background: #03CABE;border: none;" @click="exportRepairRecord">导出</el-button>
            </div>
            <div class="pdl30 pdr30 hei80V">
                <el-table ref="multipleTable" :data="queryLimitList" tooltip-effect="dark" style="width: 100%"
                class="mg_top_20" border=""  id="repairPlanTable"
                :default-sort="{prop: 'date', order: 'descending'}" height="83%">
                <!-- <el-table-column type="selection" width="50">
                </el-table-column> -->
                <el-table-column type="index" label="序号" width="60">
                </el-table-column>
                <el-table-column prop="devName" label="设备名称" width="">
                </el-table-column>
                <el-table-column prop="devID" label="设备编号" width="">
                </el-table-column>
                <el-table-column prop="devType" label="设备类型" width="">
                </el-table-column>
                <el-table-column prop="devModel" label="设备型号" width="">
                </el-table-column>
                <el-table-column prop="devLevel" label="设备等级" width="">
                </el-table-column>
                <el-table-column prop="ctrlSys" label="控制系统" width="">
                </el-table-column>
                <el-table-column prop="WorkshopSection" label="所属部门" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="UserDepartment" label="使用部门" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="devAdmin" label="设备管理员" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="installPoint" label="安装位置" width="120" show-overflow-tooltip>
                </el-table-column>
                 <el-table-column prop="referPrice" label="采购价格(元)" width="120" show-overflow-tooltip >
                </el-table-column>
                 <el-table-column prop="unit" label="计量单位" width="120" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column prop="Purchasingdate" label="采购日期" width="" :formatter="formatDate"
                    show-overflow-tooltip>
                </el-table-column> -->
                <!--  -->
                <el-table-column prop="supplier" label="供应商" width="" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="inspWorker" label="点检人">
                </el-table-column>
                <el-table-column prop="repWorker" label="维修人">
                </el-table-column>
                <el-table-column prop="manufacturer" label="生产商" width="" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="inwhTime" label="入库时间" width="200" :formatter="formatDate" show-overflow-tooltip>
                </el-table-column>
                 <el-table-column prop="remarks" show-overflow-tooltip label="备注" width="">
                </el-table-column>
              
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" class="color_009BFD  pdt_0 pdb_0">
                            <span style="border-bottom: 1px solid #009BFD;" @click="check(scope.row)" >查看</span>
                        </el-button>
                        <el-button type="text" class="color_009BFD  pdt_0 pdb_0">
                            <span style="border-bottom: 1px solid #009BFD;" @click="changeData(scope.row)">修改</span>
                        </el-button>
                        <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                            <span style="border-bottom: 1px solid #009BFD;" @click="singleDelete(scope.row.ID)">删除</span>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
                <div class="flex justify_end">
                    <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                        :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
                </div>
            </div>
        </div>
        


        <!-- dialog3 新加 -->
            <el-dialog :title="dialogTitle" :visible.sync="dialogTableVisibleAdd" top='4vh' width="54%">
                <div class="flex justify_between pdl30 pdr30">
                    <el-form :inline="true" ref="ruleForm" :model="formInline" :label-width="labelWidth1"
                        :rules="rulesFrom" class="mg_top_20 flex  align_center flex_wrap">
                        <el-form-item :span="12" label="设备编号：" prop="devID">
                            <el-input v-model="formInline.devID"  placeholder="请输入" class="width_180" :disabled="formDisable1"  >
                            </el-input>
                        </el-form-item>
                        <el-form-item label="所属部门：" prop="WorkshopSection">
                             <el-input v-model="formInline.WorkshopSection" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                            <!-- <el-select v-model="formInline.WorkshopSection" :disabled="formDisable" placeholder="请输入" class="width_180"
                                clearable>
                                 <el-option v-for="(item,index) in workshopArray" :key="index" :label="item.name"
                                    :value="item.id">
                                </el-option> 
                            </el-select> -->
                        </el-form-item>
                        <el-form-item label="设备名称：" prop="devName">
                            <el-input v-model="formInline.devName" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="生产商：">
                            <el-input v-model="formInline.manufacturer" :disabled="formDisable" placeholder="请输入" class="width_180"
                        >
                            </el-input>
                        </el-form-item>   
                      
                        <el-form-item label="计量单位：" prop="unit">
                            <el-input v-model="formInline.unit" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                        </el-form-item>
                        
                        <el-form-item label="控制系统：">
                            <el-input v-model="formInline.ctrlSys" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="供应商：">
                            <el-input v-model="formInline.supplier" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                           
                        </el-form-item>
                        <el-form-item label="设备型号：" prop="devModel">
                            <el-input v-model="formInline.devModel" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="使用部门：">
                             <el-input v-model="formInline.UserDepartment" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                            <!-- <el-select v-model="formInline.UserDepartment" :disabled="formDisable" placeholder="请输入" class="width_180"
                                clearable>
                                 <el-option v-for="(item,index) in workshopArray" :key="index" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select> -->
                        </el-form-item>
                        <el-form-item label="设备类型：" prop="devType">
                            <el-input v-model="formInline.devType" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                            <!-- <el-select v-model="formInline.EquipmentType" :disabled="formDisable" placeholder="请输入" class="width_180" clearable>
                                <el-option v-for="item in selectList" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select> -->
                        </el-form-item>
                        <el-form-item label="设备管理员：" prop="devAdmin">
                            <el-input v-model="formInline.devAdmin" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                           
                        </el-form-item>
                        <el-form-item label="安装位置：" prop="installPoint">
                            <el-input v-model="formInline.installPoint" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="设备等级：" prop="devLevel">
                            <el-input v-model="formInline.devLevel" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                           
                        </el-form-item>
                        <el-form-item label="维修人：" prop="repWorker">
                            <el-input v-model="formInline.repWorker"  placeholder="请输入" :disabled="formDisable" class="width_180">
                            </el-input>
                           
                        </el-form-item>
                        <el-form-item label="点检人：" prop="inspWorker">
                            <el-input v-model="formInline.inspWorker" :disabled="formDisable" placeholder="请输入" class="width_180">
                            </el-input>
                          
                        </el-form-item>
                        <el-form-item label="采购价格（元）：" prop="referPrice">
                            <el-input v-model="formInline.referPrice" :disabled="formDisable"  oninput="if(value<0){value=0}" placeholder="请输入" class="width_180" type="number">
                            </el-input>
                          
                        </el-form-item>
                        <el-form-item label="入库时间：" prop="inwhTime">
                             <el-date-picker  value-format="timestamp" prefix-icon="el-icon-date" v-model="formInline.inwhTime"
                                type="datetime" :clearable="false" placeholder="选择日期时间" style="width:180px">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="备注：" :label-width="labelWidth1">
                            <el-input type="textarea" :rows="4" :disabled="formDisable" style="width: 30vw" v-model="formInline.remarks" resize="none" placeholder="请输入">
                        </el-input>
                    </el-form-item>
                    </el-form>
                </div>
                <!-- 按钮 -->
                <div class="flex justify_center pdb_20 pdt_10" v-if="btnDisable">
                    <el-button type="primary" @click="dialogTableVisibleAdd = false">取消</el-button>
                    <el-button type="primary" @click="addTest">确定</el-button>
                </div>
            </el-dialog>

            
    </div>
</template>
<script>
    import {
        thirdpartyDeviceStockQueryLimit,
        thirdpartyDeviceStockQuery,
        thirdpartyDeviceStockInsert,
        thirdpartyDeviceDelete,
        thirdpartyDeviceStockUpdate,

        //equipmentLedgerAlarmTypeSelect,//设备类型
        //equipmentLedgerDepartment, //部门选择下拉框
       // equipmentLedgerAlarmLevelSelect,//设备等级
    } from "@/api/api_systemConfig";
    export default {
        data() {
            return {
                disabled:true,
                value: '',
                options: [],
                options2: [],
                queryList: [],
                groupPerson: '',
                form: {
                    userID: '',
                    groupSettingsCategory: '',
                    groupName: '',
                    groupPerson: '',
                    remarks: ''
                },
                table: {
                    ID: '',
                    groupSettingsCategory: '',
                    groupName: '',
                    groupPerson: '',
                    remarks: ''
                },
                groupSettingsCategory: [],
                dialogFormVisible: false,
                dialogFormVisible1: false,
                userID: '',
                userName: '',
                formLabelWidth: '120px',
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                
                queryLimitList:[],
                loading:true,
                permission: {},
                formInline : {},
                formInlineEdit:{},
                workshopArray: [],
                dialogTableVisibleAdd:false,
                labelWidth1: '210px',
                labelWidth2: '80px',
                rulesFrom: {
                    // EquipmentType: [{
                    //     required: true,
                    //     message: '请选择设备类型',
                    //     trigger: 'blur'
                    //}],
                    // EquipmentLevel: [{
                    //     required: true,
                    //     message: '请选择设备等级',
                    //     trigger: 'blur'
                    // }],
                    unit: [{
                        required: true,
                        message: '请输入计量单位',
                        trigger: 'blur'
                    }],
                    devID: [{
                        required: true,
                        message: '请输入设备编号',
                        trigger: 'blur',
                    }, {
                        min: 1,
                        max: 11,
                        message: '长度在 1 到 10 个数字',
                        trigger: 'blur'
                    }],
                    devName:[{
                        required: true,
                        trigger: 'blur',
                        message: '请输入设备名称',
                        }],
                    WorkshopSection:[{
                        required: true,
                        trigger: 'blur',
                        message: '请输入所属部门',
                    }],
                    devModel:[{
                        required: true,
                        trigger: 'blur',
                        message: '请输入设备型号',
                    }],
                    devType:[{
                        required: true,
                        trigger: 'blur',
                        message: '请输入设备类型',
                    }],
                    // devAdmin:[{
                    //     required: true,
                    //     trigger: 'blur',
                    //     message: '请输入设备管理员名称',
                    // }],
                    // installPoint:[{
                    //     required: true,
                    //     trigger: 'blur',
                    //     message: '请输入设备安装位置',
                    // }],
                    // repWorker:[{
                    //     required: true,
                    //     trigger: 'blur',
                    //     message: '请输入设备维修人名称',
                    // }],
                    // inspWorker:[{
                    //     required: true,
                    //     trigger: 'blur',
                    //     message: '请输入设备点检人名称',
                    // }],
                    referPrice:[{
                        required: true,
                        trigger: 'blur',
                        message: '请输入设备采购价格',
                    }]
                },
                selectList: [],
                selectList1: [],
                checkData: {},
                formDisable:false,
                formDisable1:false,
                btnDisable:true,
                dialogTitle:"",
                
            }
        },
        methods: {
            //正则表达式限制文本框只能输入正数，小数
            // BlurText(e) {
            // let boolean = new RegExp("/[^\d.]/g,''").test(e.target.value)
            // if(!boolean) {
            // this.$message.warn('请输入正整数')
            // e.target.value = ' '
            // }
            // },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            initView(){
                //加载动画
                this.getLoading();
                //请求table数据
                this.thirdpartyDeviceStockQueryLimit();

                //this.equipmentLedgerDepartment();

                //部门选择下拉框
                //this.equipmentLedgerDepartment();
                //设备类型
                //this.equipmentLedgerAlarmTypeSelect();
                //设备等级
                //this.equipmentLedgerAlarmLevelSelect()
                // this.thirdpartyDeviceStockUpdate();
                // this.thirdpartyDeviceDelete();
                // this.thirdpartyDeviceStockInsert();
            },
            //加载动画
            getLoading() {
                const loading = this.$loading({
                    lock: true, //lock的修改符--默认是false
                    text: 'Loading', //显示在加载图标下方的加载文案
                    spinner: 'el-icon-loading', //自定义加载图标类名
                    background: 'rgba(0, 0, 0, 0.7)', //遮罩层颜色
                    target: document.querySelector('#table') //loadin覆盖的dom元素节点
                });
                loading.close() //成功回调函数停止加载
            },
            //页码跳转
            async thirdpartyDeviceStockQueryLimit() {
                var param = {
                    // permission: this.permission
                    userID:this.userID,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    permission: this.permission,
                }
                const selectRes = await thirdpartyDeviceStockQueryLimit(param);
                this.pageQuantity = selectRes.pageQuantity
                console.log('selectRes====>',selectRes);
                this.queryLimitList = selectRes.rows;
                this.loading = false;
            },
            //页码跳转
            handleCurrentChange(val) {
                this.currentPage = val;
                this.thirdpartyDeviceStockQueryLimit(); //查询table数据的方法

            },
            //新增
            addData(){
                //let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.formDisable =false;
                this.formDisable1=false;
                this.btnDisable =true;
                this.dialogTitle="新增设备基本信息";
                this.formInline ={};
                this.formInlineEdit = { };
                //获取用户权限
                // if(userInfo.deviceManageModify){
                //     this.formInline ={
                //         devName:" ",
                //         inspWorker:" ",
                //         repWorker:" "
                //     };
                //      //打开新增页面dialog3
                   this.dialogTableVisibleAdd = true;
                  
                // // }else{
                // //     this.$message({
                // //         message: '警告哦，您没有权限选择此项',
                // //         type: 'warning'
                // //     });
                // }
            },
            //导出
            exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '第三方设备台账管理')
            },
            //确认新增按钮
            addTest(){

                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) { //验证通过
                        //新增接口 
                        if(Object.values(this.formInlineEdit).length >0){
                            this.DeviceStockUpdate();
                         }
                        else{
                            this.thirdpartyDeviceStockInsert();
                        } 
                    } else {
                        return false;
                    }
                });
                
            },
            //单个删除
            singleDelete(id){
                //let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                //获取用户权限
                //if(userInfo.deviceManageModify){
                    this.$confirm('此操作将永久删除该设备, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.currentPage = this.delData.delData(this.queryLimitList.length, this.pageQuantity, this
                            .currentPage)
                        this.thirdpartyDeviceDelete(id)
                    }).catch(() => {

                    });
                // }else{
                //     this.$message({
                //         message: '警告哦，您没有权限选择此项',
                //         type: 'warning'
                //     });
                // }
            },
            //查看数据
            check(row){
                console.log("row",row)
                this.dialogTableVisibleAdd = true;
                this.dialogTitle ="查看设备基本信息";
                this.formDisable = true;
                this.formDisable1 = true;
                this.btnDisable = false;
                this.formInline = row;
                
            },
            //修改
            changeData(row){    
                console.log('changeData',row)
                //let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                this.formInline ={};
                this.btnDisable =true;
                this.formInlineEdit = { };
                this.dialogTitle ="修改设备基本信息";
                //if(userInfo.deviceManageModify){
                    this.formDisable = false;
                    this.formDisable1 = true;
                   
               // }else{
                //     this.formDisable = true;
                //     this.formDisable1 = false;
                // }
                this.formInline = row;
                this.formInlineEdit = row;
                this.dialogTableVisibleAdd =true;
             },
            // 部门选择下拉框
            // async equipmentLedgerDepartment() {
            //     var param = {
            //         userID:this.userID
            //     }
            //     const selectRes = await equipmentLedgerDepartment(param);
            //     this.workshopArray = selectRes.data
            // },
            //设备类型下拉框
            // async equipmentLedgerAlarmTypeSelect() {
            //     this.selectList = [];
            //     var param = {
            //         organization: this.checkData
            //     }
            //     const selectRes = await equipmentLedgerAlarmTypeSelect(param);
            //     this.selectList = selectRes.data;
            // },
            //设备等级下拉框
            // async equipmentLedgerAlarmLevelSelect() {
            //     this.selectList1 = [];
            //     var param = {
            //         organization: this.checkData
            //     }
            //     const selectRes = await equipmentLedgerAlarmLevelSelect(param);
            //     this.selectList1 = selectRes.data;
            // },
            //新增接口
            async thirdpartyDeviceStockInsert() {
                //this.selectList1 = [];
                console.log(this.formInline);
                var param = {
                    userID:this.userID,
                    ID:this.formInline.ID,
                    devID:this.formInline.devID,
                    devName:this.formInline.devName,
                    devType:this.formInline.devType,
                    devModel:this.formInline.devModel,
                    devLevel:this.formInline.devLevel,
                    ctrlSys:this.formInline.ctrlSys,
                    workshopSection:this.formInline.WorkshopSection,
                    userDepartment:this.formInline.UserDepartment,
                    devAdmin:this.formInline.devAdmin,
                    inspWorker:this.formInline.inspWorker,
                    repWorker:this.formInline.repWorker,
                    installPoint:this.formInline.installPoint,
                    referPrice:this.formInline.referPrice,
                    unit:this.formInline.unit,
                    supplier:this.formInline.supplier,
                    manufacturer:this.formInline.manufacturer,
                    remarks:this.formInline.remarks,
                    inwhTime:this.formInline.inwhTime
                }
                const selectRes = await thirdpartyDeviceStockInsert(param);
                console.log(selectRes)
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisibleAdd = false
                    this.formInline = {}
                    this.thirdpartyDeviceStockQueryLimit();
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning',
                    });
                }
                this.dialogTableVisibleAdd = false;
            },
            //删除接口
            async thirdpartyDeviceDelete(id){
                var param = {
                    ID: id
                }
                const selectRes = await thirdpartyDeviceDelete(param);
                //判断是否删除成功
                if (selectRes.data) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.thirdpartyDeviceStockQueryLimit()
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    });
                }
            },
            //修改  thirdpartyDeviceStockUpdate
            async DeviceStockUpdate(){
                console.log("----------LLLL",this.formInline);
                var param = {
                            devID:this.formInline.devID,
                            devName:this.formInline.devName,
                            devType:this.formInline.devType,
                            devModel:this.formInline.devModel,
                            devLevel:this.formInline.devLevel,
                            ctrlSys:this.formInline.ctrlSys,
                            WorkshopSection:this.formInline.WorkshopSection,
                            UserDepartment:this.formInline.UserDepartment,
                            devAdmin:this.formInline.devAdmin,
                            inspWorker:this.formInline.inspWorker,
                            repWorker:this.formInline.repWorker,
                            installPoint:this.formInline.installPoint,
                            referPrice:this.formInline.referPrice,
                            unit:this.formInline.unit,
                            supplier:this.formInline.supplier,
                            manufacturer:this.formInline.manufacturer,
                            remarks:this.formInline.remarks,
                            userID:this.userID,
                            ID:this.formInline.ID,
                            inwhTime:this.formInline.inwhTime
                }
                const selectRes = await thirdpartyDeviceStockUpdate(param);
                 console.log("------PPP",selectRes)
                //判断是否删除成功
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogTableVisibleAdd = false
                    this.formInline = {}
                    this.thirdpartyDeviceStockQueryLimit();
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    });
                } 
                this.dialogTableVisibleAdd = false; 
                this.thirdpartyDeviceStockQueryLimit()
                this.formInlineEdit = {};
            },
            
        },
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            };
            if (localStorage.getItem('permission') != null) {
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.$nextTick(()=>{
                this.initView();
            })
            
        },
    }
</script>
<style scoped>
    .hei80V {
        height: 78%;
    }
</style>
<style lang="less" scoped>
    ::v-deep .el-form-item {
        margin-bottom: 14px
    }
</style>